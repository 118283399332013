.content {
    display: grid;
    grid-template-columns: auto 8.5rem;
}

.undercontent {
    display: grid;
    grid-template-columns: 3.2rem auto;
    padding: 1rem;
}



.overcontent:hover {
    cursor: pointer;
    background-color:#F7FAFC;
}

.overcontent:hover div {
    transform: translateX(.2rem);
}

.overcontent p,
.overcontent:hover div {
    transition: transform 200ms;
}


@media only screen and (max-width: 600px) {
    .content {
        grid-template-columns: 1fr;
    }
}