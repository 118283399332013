.container {
	display: grid; /* Fixes width issue */
}

.wrapper {
	height: 100%;
	width: 100%;
	overflow: auto;
	--cell-width: 9.5rem;
	--cell-height: 5rem;
	--border-color: rgb(216, 216, 216);
	--light-green: rgb(239, 252, 239);
	border-radius: 5px;
	border: 1px solid var(--border-color);
}

.row {
	display: grid;
	grid-template-columns: repeat(var(--cell-amount), minmax(var(--cell-width), 1fr));
}

/* Basic styling for every cell */
.row > * {
	width: 100%;
	min-height: var(--cell-height);
	transition: height 2s ease-in;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 1rem;
}

.row:not(:nth-of-type(1)):hover > * {
	border-top: 2px solid var(--border-color);
	border-bottom: 2px solid var(--border-color);
	background-color: #D2E8F8 !important;
}

.row:not(:nth-of-type(1)) > * {
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
}

.row:not(:nth-of-type(1)):hover > :last-child {
	border-right: 2px solid var(--border-color);
}

.row:not(:nth-of-type(1)):hover > :nth-child(1) {
	border-left: 2px solid var(--border-color);
	border-right: 2px solid var(--border-color);
}

.row:not(:nth-of-type(1)) > :nth-child(1) {
	border-left: 2px solid transparent;
	border-right: 2px solid transparent;
}

.row:not(:nth-of-type(1)) > div:not(:nth-of-type(1)):hover {
	background-color: rgba(233, 244, 252, 0.8) !important;
}

.row:not(:nth-of-type(1)) > div:nth-of-type(1):hover {
	background-color: #edf6fc !important;
}

/*
 * Top row
 */
.row:nth-of-type(1) {
	position: sticky;
	top: 0;
	z-index: 3;
}

.row:nth-of-type(1) > * {
	border-bottom: 1px solid var(--border-color);
	cursor: default;
	background-color: #2C7A7B /*rgb(22, 26, 61)*/;
	box-shadow: 2px 4px 5px rgba(19, 19, 19, 0.1);
	color: white;
	font-weight: 500;
}

/* Adding the empty square at top left corner */
.row:nth-of-type(1):first-child:before {
	content: 'Brukere:';
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 0.35rem;
	font-weight: bolder;
	font-size: 1.5rem;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	height: var(--cell-height);
	background-color: white;
	border-right: 1px solid var(--border-color);
}

/*
 * Cells not in top row
 */
.userItem {
	display: flex;
	flex-direction: column;
	padding: 0.25rem;
	align-items: flex-start;
}

.row:not(:nth-of-type(1)).expanded .userItem {
	cursor: nesw-resize;
}

/* Making the first cell in every row sticky (mimicks a sticky left collumn)*/
.row:not(:nth-of-type(1)) > div:nth-of-type(1) {
	position: sticky;
	left: 0;
	background-color: white;
	z-index: 5;
	border-right: 1px solid var(--border-color);
	box-shadow: 2px 4px 3px rgba(19, 19, 19, 0.15); 
	cursor: nesw-resize;
}

.row:not(:nth-of-type(1)) > div {
	cursor: pointer;
}

.row:not(:nth-of-type(1)).expanded > div {
	cursor: auto;
}

.row:not(:nth-of-type(1)) > :not(:nth-of-type(1)) {
	display: flex;
	flex-direction: column;
}

/* 1, 3, 5th etc cell with different colour */
.row:not(:nth-of-type(1)) > *:nth-of-type(2n) {
	background-color: var(--light-green);
}

.expanded > * {
	justify-content: flex-start;
}

.expanded > *:not(:nth-of-type(1)) {
	background-color: rgba(210, 232, 248, 0.1) !important;
}

.accessPreview {
	width: 1.65rem;
	height: 1.65rem;
	border-radius: 40%;
	border: 1px solid var(--border-color);
}

/*
 * Diff menu
 */
.diffMenu {
	width: var(--sidebar-width);
	position: fixed;
	background-color: white;
	height: 100vh;
	top: 0;
	left: calc(-1 * var(--sidebar-width));
	transition: transform 300ms ease-in-out;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: flex-start;
	padding: 1rem;
	z-index: 100;
}

.showDiff {
	transform: translateX(var(--sidebar-width));
}

.changeItemContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
	box-shadow: 2px 4px 9px rgba(19, 19, 19, 0.1);
	padding: 1rem;
	border-radius: 5px;
	width: 90%;
}
