.spinner,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  margin: 0 auto;
  font-size: 10px;
  
  text-indent: -9999em;
  border-width: 0.8em;
  border-style: solid;
  border-top-color: rgba(255, 255, 255, 0.3) !important;
  border-right-color: rgba(255, 255, 255, 0.3) !important;
  border-bottom-color: rgba(255, 255, 255, 0.3) !important;
  border-left: 0.8em solid #ffffff;
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
  -ms-transform: translateX(-50%) translateY(-50%) translateZ(0);
  -webkit-animation: load8 0.7s infinite linear;
  animation: load8 0.7s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.xs, .xs:after {
    width: 18px !important;
    height: 18px !important;
    border-width: 0.3em !important;
}

.sm, .sm:after {
    width: 30px !important;
    height: 30px !important;
    border-width: 0.3em !important;
}

.md, .md:after {
    width: 42px !important;
    height: 42px !important;
    border-width: 0.4em !important;
}

.lg, .lg:after{
    width: 54px !important;
    height: 54px !important;
    border-width: 0.7em !important;
}

.xl, .xl:after {
    width: 60px !important;
    height: 60px !important;
}
