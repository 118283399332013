.replyItem {
    display: grid;
    grid-template-rows: 2.5rem 1fr;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    font-size: 0.9rem;
    width: calc(100% - 1.5rem);
}

.replyItemHeader {
    display: grid;
    grid-template-columns: 2rem 1fr;
    align-items: center;
}
