.indent-2 {
    margin-left: 24px;
}

.indent-3 {
    margin-left: 42px;
}

.indent-4 {
    margin-left: 56px;
}

.indent-5 {
    margin-left: 68px;
}

.indent-6 {
    margin-left: 72px;
}

.indent-7 {
    margin-left: 82px;
}


.status-icon {
    min-width: 16px;
    min-height: 16px;
}