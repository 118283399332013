.chatMessages {
/*    border: 1px solid #319795;*/
    height: calc(100vh - 10rem);
}

.chatMessages > :first-child {
    padding-top: 4.5rem;
}


.chatHeader {
    position: absolute;
    background-color: transparent;
    z-index: 10;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.05);
    top: 0;
    left: 0;
    width: calc(100% - 2rem);
    padding: 0.75rem 1.5rem 0.4rem;

    
}

@supports not (backdrop-filter: blur(5px)) {
    .chatHeader {
        background-color: rgba(255, 255, 255, 0.8);

    }
  }