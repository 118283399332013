.root {
    width: 100vh;
    max-width: 340px;
    max-height: 80vh;

    position: relative;
    border: 1px solid #e2e8f0;
}

.root::-webkit-scrollbar {
    width: 0;
}


.popup {
    transform: translate3d(12px, 71.9531px, 0px) !important;
}