.criteriaHeader {
    position: sticky;
    top: -1px;
    z-index: 20;
    border-bottom: 0px solid #ebeeed;
    box-shadow: 0.1px 3px 2px rgba(235, 238, 237, 1);
    transition: background-color 200ms;
}

.darken {
    background-color: rgb(250, 250, 250);
}

.header {
    transition: transform 300ms;
}

.transform {
    transform: translateY(0.8rem);
}

.criteriaHeaderExtra {
    position: absolute;
    font-size: 1rem;
    left: 0;
    bottom: 5px;
    padding: 0.3rem;
    opacity: 0.7;
    width: 100%;
    transform: translateY(0rem);
    text-align: center;
    transition: all 300ms;
}

.initExtraHeader {
    transform: translateY(1rem);
    opacity: 0;
}