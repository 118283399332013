/* Source: https://hankchizljaw.com/wrote/a-modern-css-reset/ */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
a,
abbr,
acronym,
address,
applet,
area,
article,
aside,
audio,
b,
base,
basefont,
bdo,
big,
blockquote,
body,
br,
button,
canvas,
caption,
center,
cite,
code,
col,
colgroup,
datalist,
dd,
del,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
frame,
frameset,
head,
header,
h1,
h2,
h3,
h4,
h4,
h5,
h6,
hr,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
link,
main,
map,
mark,
meta,
meter,
nav,
noscript,
object,
optgroup,
option,
p,
param,
pre,
progress,
q,
s,
samp,
section,
select,
small,
source,
span,
strike,
strong,
style,
sub,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
title,
tr,
u,
var,
video,
wbr
{
  margin: 0;
}


/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eceeed !important;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Making the focus-ring prettier */
:focus {
	outline:#c1e0fe solid 3px;
	outline: rgba(131, 192, 253, .5) solid 3px;
	outline-offset: 2.5px;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
