.line  {
    position: relative;
    z-index: 1;
    --component-space: -1rem;
    --min-height: 3rem;
    margin-bottom: var(--component-space);
}

.revisionInner {
    margin-top: 2rem;
    min-height: var(--min-height);
    width: 80%;
    font-size: 0.9rem;
}

.line:before,
.line:after {
    content: '';
    background-color: #319795;
    position: absolute;
    left: 1rem;
    z-index: -1;
}

.line:before {
    height: calc(var(--min-height) + var(--component-space));
    width: 3px;
    top: calc(-1 * var(--min-height) - var(--component-space));
}

