.root {
    height: 20px;
    min-width: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: 50%;
    
    position: absolute;
    top: -3px;
    right: -3px;
}