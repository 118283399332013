.root {
    padding: 8px;
    background-color: white;

    position: sticky;
    top: 0;
    height: 100vh;

    overflow-y: scroll;
}

.root::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .drawer {
        position: absolute !important;
        top: 0px;
        left: -100%;
        width: 100vw !important;
        max-width: var(--sidebar-width) !important;
    }
}

.open {
    animation: openDrawer .5s ease-in-out;
    animation-fill-mode: forwards;
}

.close {
  animation: closeDrawer .5s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes openDrawer {
    from {left: -100%;}
    to {left: 0;}
}

@keyframes closeDrawer {
    from {left: 0;}
    to {left: -100%;}
}