.line{
    position: relative;
    z-index: 1;
    --component-space: 0.5rem;
    padding-bottom: 0.3rem;
}

.line:before,
.line:after {
    content: '';
    background-color: #319795;
    position: absolute;
    left: 2rem;
    z-index: -1;
}

.line:before {
    height: calc(100% + var(--component-space));
    width: 3px;
    bottom: 0;
}

.line:after {
    width: 1rem;
    height: 3px;
    bottom: 50%;
}

