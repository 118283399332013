.selectList {
    cursor: pointer;
    display: block;
    width: 100%;
    vertical-align: middle;
    background-color: rgb(250, 250, 250);
    padding: 4px 6px;
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 211);
}

.selectList:hover,
.selectList:focus {
   background-color: rgb(254, 254, 254);
}