.root {
    display: grid;

    grid-template-columns: var(--sidebar-width) auto;
}

.content {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-template-areas: 
        "back filters"
        "todo stream"
    ;
}

@media only screen and (max-width: 1023px) {
    .root {
        grid-template-columns: 1fr;
    }

}

@media only screen and (max-width: 1280px) {
    .content {
        grid-template-columns: 1fr;
        grid-template-areas: 
            "back"
            "todo"
            "filters"
            "stream"
        ;
    }
}