.hover {
    position: absolute;
    bottom: -2.3rem;
    width: var(--width);
    text-align: center;
    color: white;
    background-color: rgba(116, 116, 116, 0.789);
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    cursor: default;
    z-index: 50;
}

.center {
    transform: translateX(-35%);
}