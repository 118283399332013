.line {
    position: relative;
    z-index: 1;
    --component-space: 2.5rem;
    margin-bottom: 0.5rem;
    margin-top: var(--component-space);  ;
}

.line:last-of-type:after {
    display: none;
}

.line:before,
.line:after {
    content: '';
    height: var(--component-space);    
    width: 3px;
    position: absolute;
    left: 2rem;
    z-index: -1;
    background-color: #319795;
}

.line:before {
    top: calc(-1 * var(--component-space));
}

.line:after {
    bottom: calc(-1 * var(--component-space));
}

