.root {
    display: grid;
    grid-template-columns: 44px auto;
    position: relative;
}

.reactions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 80px));
    gap: 2px;
}

.opacity {
    opacity: 0.4;
}

.messageItem ul {
    list-style-type: '-';
    padding-inline-start: 40px;
}