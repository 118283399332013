.subcriteria,
.criteria {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.975);
}

.subcriteria {
    top: -2rem;
    left: -4rem;
    width: 10rem;
    text-align: center;
}

.criteria {
    top: -0.5rem;
    left: 2rem;
    width: 21rem;
    text-align: left;
    padding: 0.3rem;
    display: flex;
    align-content: center;
    border-radius: 0.375rem;
}

.subcriteriamodule {
    margin-right: 1rem;
}


.topmargin {
    top: -0.3rem;
}

@supports (width: max-content) {
    .subcriteria,
    .criteria {
        width: max-content;
    }

    .subcriteria {    
        left: -50%;
        transform: translateX(-25%);
    }
}