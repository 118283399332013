.line {
    position: relative;
    z-index: 2;
    --component-space: 2.5rem;
    margin-bottom: var(--component-space);
    margin-top: 2rem;
}

.line:before,
.line:after {
    content: '';
    height: calc(100% + var(--component-space));
    background-color: #319795;
    position: absolute;
    left: 2rem;
    z-index: -1;
}

.line:before {
    width: 3px;
    bottom: 0;
}

.line:after {
    width: 3px;
    top: 0;
}
