.underline {
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.22s ease-in-out 0s;
    transition: all 0.22s ease-in-out 0s;
}

.underline-selected {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.heading {
    letter-spacing: 1px;
    font-size: 0.9rem;
    font-weight: 600 !important;
    text-transform: uppercase;
}