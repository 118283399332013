/**
* NOTE: These CSS-classes are dynamically used in SideBar.jsx.
* They follow the name of the "status"-key on every sub-criterion.
* So if this value is every changed from for E.G "pending" to "waiting",
* then it has to be updated here as well.
*/

.approved,
.pending,
.disapproved {
    position: relative;
}

.approved:after,
.pending:after,
.disapproved:after {
    content: '';
    position: absolute;
    width: 0.65rem;
    height: 0.65rem;
    bottom: -0.15rem;
    right: -0.3rem;
    border-radius: 50%;
    opacity: 0.95;
}

.approved:after {
    background-color: #48BB78;
}

.pending:after {
    background-color: #ECC94B;
}

.disapproved:after {
    background-color: #E53E3E;
}