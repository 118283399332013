

.progressGrid {
    display: grid;
    grid-template-columns: 14rem 1fr;
}

.progressInnerGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
}

.filler {
    position: sticky;
    top: -1px;
    background-color: white;
    z-index: 5;
}

.gridRight {
    grid-column: 2/-1;
    display: grid;
    align-content: center;
}

.gridLeft {
    grid-column: 1/2;
}

.progressCategories {
    grid-column: 1/-1;
}

.progressCategoryHeader {
    position: sticky;
    background-color: white;
    top: 2.6rem;
    z-index: 8;
}

.sectionPart {
    margin-bottom: 2.5rem;
}

.sectionPart:not(:first-of-type) {
    margin-top: 2.5rem;
}

.categoryLeft {
    display: grid;
    grid-template-columns: 80% 1fr;
}

.categoryLeftName {
    grid-column: 1/2;
    position: sticky;
    top: 6rem;
}

.categoryLeftCriteria {
    grid-column: 2/-1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.subCriteriaProgress {
    height: 2.5rem;
    margin-top: 0.2rem;
}

.subCriteriaPhase {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
    justify-content: flex-start;
    align-items: center;
}

.innerSubPhase {
    height: 50%;
    margin: 0.5rem;
    position: relative;
}

.fadeOver {
    position: absolute;
    height: 100%;
    width: 1.5rem;
    left: -2.5rem;
    top: 0;
}

.subCriteriaPhase:before {
    content: '';
    position: absolute;
    left: -0.1rem;
    top: 0;
    height: calc(100% + 0.2rem);
    width: 0.1rem;
    background-color: lightgray;
}

.subCriteriaPhase:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% + 0.2rem);
    width: 0.1rem;
    background-color: lightgray;
}

.subCriteriaProgress:last-of-type > .subCriteriaPhase:before,
.subCriteriaProgress:last-of-type > .subCriteriaPhase:after {
    height: 100%;
}
