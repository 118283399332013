.root {
    margin-top: 24px;
    margin-bottom: 74px;
    width: 100vw;
    max-width: 900px;
    min-height: 200px;
}

@media only screen and (max-width: 600px) {
    .root {
        margin-top: unset;    
        margin-bottom: unset;        
    }
}