
.progressHeader {
    position: sticky;
    top: -1px;
    padding-top: 1rem;
    background-color: white;
    z-index: 10;
}

.progressTimelineBar {
    margin-top: 0.5rem;
    --percentage: 0%;
}

.progressbarField {
    height: 1rem;
    background-color: lightgray;
    position: relative;
}

.progressInnerGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
}

.gridRight {
    grid-column: 2/-1;
    display: grid;
    align-content: center;
}

.currentField:before {
    content: '';
    position: absolute;
    background-color:#319795;
    height: 100%;
    left: 0;
    width: var(--percentage);
}

.progressIndicator:after {
    content: '';
    position: absolute;
    background-color: rgba(61, 61, 61, 0.333);
    height: calc(100vh - 5.1rem);
    --indicator-width: 0.2rem;
    left: calc(var(--percentage) - var(--indicator-width));
    width: var(--indicator-width);
    z-index: 10;
    transition: all 200ms;
}

.progressbarField:first-of-type {
    border-top-left-radius: 0.35rem 0.35rem;
    border-bottom-left-radius: 0.35rem 0.35rem;
}

.progressbarField:last-of-type {
    border-top-right-radius: 0.35rem 0.35rem;
    border-bottom-right-radius: 0.35rem 0.35rem;
}