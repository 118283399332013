.table {
    border-radius: .28571429rem;
    font-size: 1em;
    border-collapse: separate;
    
    border-spacing: 0;
}

.celled {
    border: 1px solid rgba(0, 0, 0, .13);
}

/* .table, .table th, .table td {
    border: 1px solid rgba(0,0,0,0.13);
} */

.celled tr th, .celled tr td{
    border-left: 1px solid rgba(34,36,38,.1);
    border-bottom: 1px solid rgba(34,36,38,.1);
}

.celled tr th:first-child, .celled tr td:first-child{
    border-left: none;
}

.celled tr:last-child td {
    border-bottom: none;
}

.table thead tr:first-child>th:first-child {
    border-radius: .28571429rem 0 0 0;
}

.table thead tr:first-child>th:last-child {
    border-radius: 0 .28571429rem 0 0;
}