.root {
    padding: 9px;
    background-color: transparent;
    border-radius: 24px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
}

.checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.hover:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.box {
    border-radius: 2px;
    width: 18px !important;
    height: 18px !important;
    position: relative;
}

.checkmark {
    position: absolute;
    left: 3px;
    bottom: 3px;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}