#calendarWrapper {
    width: 100%;
    display: grid;
    grid-template-rows: 2.5em 1fr;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-size: inherit;
}

#calendarWrapper header {
    grid-row: 1/2;
    font-size: 2em;
    box-sizing: inherit;
}

#calendarWrapper header select {
    cursor: pointer;
}

#calendarWrapper header select:last-of-type {
    margin-left: 0.5rem;
}

#calendar {
    display: grid;
    grid-template-columns: 1fr 4em;
    grid-template-rows: 4rem 1fr;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

#calendar #calendarDays,
#calendar #calendarGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    padding: 0 1em;
    box-sizing: inherit;
    margin: 0;
}

#calendar #calendarDays {
    justify-items: center;
    grid-row: 1/2;
    grid-column: 1/2;
}

#calendar #calendarGrid {
    grid-template-rows: repeat(6, 1fr);
    justify-items: flex-start;
    grid-row: 2/-1;
    grid-column: 1/2;
    width: 100%;
}

#calendar #calendarGrid > button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0.4em;
    padding: 1em 0.2em;
    width: 90%;
    background-color: rgba(108, 108, 108, 0.02);
    border-radius: 3px;
    border: 2px solid transparent;
    transition: box-shadow 200ms, border 100ms;
    box-shadow: 1px 1px rgba(0, 0, 0, 0);
    box-sizing: inherit;
}

#calendar #calendarGrid button.selected {
    outline: none;
    border: 2px solid #319795;
    box-sizing: inherit;
    font-weight: bold;
}

#calendar #calendarGrid button[disabled],
#calendar #calendarGrid button[disabled]:focus {
    outline: none;
    border: 2px solid transparent;
    box-sizing: inherit;
    cursor: not-allowed;
    background-color: rgba(30, 39, 51, 0.2);
    box-shadow: 1px 1px rgba(0, 0, 0, 0);

}

#calendar #calendarGrid > *:hover {
    border: 2px solid rgba(72, 72, 72, 0.5);
    box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    box-sizing: inherit;
}

#calendar #navigationWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    grid-row: 2/-1;
    grid-column: 2/-1;
    box-sizing: inherit;
    padding: 1em;
    margin: 0;
}

#calendar #navigationWrapper button {
    cursor: pointer;
    background: transparent;
    border: 0;
    font-size: 3em;
    width: 1.6em;    
    box-sizing: inherit;
    padding: 0.1em;
    margin: 0;
}

#calendar #navigationWrapper button:hover {
    opacity: 0.75;
}


#calendar #navigationWrapper button:last-of-type {
    margin-top: 2.5em;
}