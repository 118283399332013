.commentInputContainer {
    display: grid;
    grid-template-columns: auto 1fr;
}

.dummyInput {
    width: 100%;
    border: solid;
}


.dummyInput::placeholder,
.dummyInput:placeholder-shown {
    color: rgb(83, 83, 83);
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formContainerTextaera {
    resize: none;
    border: solid;
}

.formContainer .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.formContainer .buttonContainer button {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    min-height: 2.6rem;
    min-width: 5rem;
}

.formContainer .buttonContainer button:disabled {
    cursor: not-allowed;
}

/* Cancel button */

.formContainer .buttonContainer .buttonCancel {
    border: 1px solid #9dcff9;
    background-color: white;
}

.formContainer .buttonContainer .buttonCancel:not(:disabled):hover,
.formContainer .buttonContainer .buttonCancel:not(:disabled):active,
.formContainer .buttonContainer .buttonCancel:not(:disabled):focus {
    background-color:#9dcff9;
    
}


/* Opacity on submitButton when hovering on cancel. 
The other way around has to be done with JS. */
.formContainer .buttonContainer .buttonCancel:not(:disabled):hover + .buttonSubmit {
    opacity: 0.5;
}


/* Submit button */

.formContainer .buttonContainer .buttonSubmit {
    background-color: #319795;
    color: white;
}

.formContainer .buttonContainer .buttonSubmit:not(:disabled):hover,
.formContainer .buttonContainer .buttonSubmit:not(:disabled):active,
.formContainer .buttonContainer .buttonSubmit:not(:disabled):focus {
    text-decoration: underline;
    background-color: #2b8685;
}

