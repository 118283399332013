
.text1 {
    position: absolute;
    top: 44%;
    left: 15%;
    font-size: 80%;
}

.text2 {
    position: absolute;
    top: 48%;
    right: 15%;
    font-size: 80%;
                   
}    
    
@media only screen and (max-width: 600px) {
    .svg {
        width: 80vw;
    }
}

@media only screen and (max-width: 750px) {
    .text1 {
        left: 2%;
    }

    .text2 {
        right: 18%;
    }

    .text1, .text2 {
        font-size: 1.8vw;
    }
 }
