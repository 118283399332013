.root {
    width: 100vh;
    max-width: 360px;
    min-height: 400px;
    max-height: 80vh;

    position: relative;
    border: 1px solid #e2e8f0;
}

.root::-webkit-scrollbar {
    width: 0;
}


.popup {
    transform: translate3d(12px, 71.9531px, 0px) !important;
}

@media only screen and (max-width: 600px) {
    .root {
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: none !important;
    }
    
    .popup {
        transform: translate(0) !important;
    }


}