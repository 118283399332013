.root {
    letter-spacing: 1px;
    font-size: 0.9rem;
    font-weight: 600 !important;
    text-transform: uppercase;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sm {
    width: 30px;
    height: 30px;

    font-size: 0.8rem;
}

.smd {
    width: 34px;
    height: 34px;

    font-size: 0.9rem;
}

.md {
    width: 40px;
    height: 40px;

    font-size: 1.0rem;
}

.lg {
    width: 56px;
    height: 56px;
    font-size: 1.2rem;
}

.xl {
    width: 200px;
    height: 200px;
    font-size: 100px;
    border: 0px;
}
.sm > div {
  
}

.md > div {
    margin-bottom: 3px;
}

.lg > div {
    margin-bottom: 3px;
}


.initials {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4%;
    color: white;
    height: 100%;
    background-color:black;
  }