.root {
    display: grid;
    grid-template-columns: 200px auto;
}

.content {
    width: 100vw;
    height: 100vh;
    max-width: 800px;
    max-height: 700px;
}

.content::-webkit-scrollbar {
    display: none;
}