


.root {
    display: grid;


    grid-template-columns: auto;
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;

    width: 100%;
    min-height: 80vh;
}



.name{
   
    width: 98vw;
    max-width: 500px;
   
}